import React from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get, head } from 'lodash';
import { Pane, Heading, Paragraph, Badge, Icon, Avatar } from 'evergreen-ui';
import { orgPath } from 'lib/urls';
import NoOrgsAvailable from './NoOrgsAvailable';

const OrgSelector = ({ seats, onSelect }: any) => {
  const { t } = useTranslation();

  if (!seats || seats.length === 0) {
    return <NoOrgsAvailable />;
  }

  // If there's only a single org then we might as well redirect the user there
  if (seats.length === 1) {
    return <Redirect to={orgPath(seats[0].organization)} />;
  }

  return (
    <Pane flex="1" display="flex" justifyContent="center" alignItems="center">
      <Pane elevation={1} background="white" maxWidth={400}>
        <Pane padding={24}>
          <Heading size={600}>{t('orgs.select.heading')}</Heading>
          <Paragraph marginTop={8} color="muted">
            {t('orgs.select.body')}
          </Paragraph>
        </Pane>

        <Pane>
          {seats
          .filter((seat: { organization: null; }) => seat.organization !== null)
          .map((seat: any) => (
            <Pane
              key={seat.id}
              borderTop
              display="flex"
              flexDirection="row"
              alignItems="center"
              cursor="pointer"
              padding={16}
              background="blueTint"
              onClick={() => onSelect(seat.organization.slug)}
            >
              <Avatar
                src={seat.organization.logo && seat.organization.logo.url}
                name={seat.organization.name}
                size={24}
                marginRight={8}
              />
              <Heading size={400} marginBottom={0}>
                {seat.organization.name}
              </Heading>
              <Pane marginLeft="auto" display="flex" justifyContent="center">
                <Badge color="teal" size={300}>
                  {get(head(seat.roles), 'name', 'N/A')}
                </Badge>
                <Icon icon="chevron-right" color="muted" marginLeft={8} />
              </Pane>
            </Pane>
          ))}
        </Pane>
      </Pane>
    </Pane>
  );
};

export default OrgSelector;
